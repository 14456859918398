<template>
  <div class="about__item">
    <div class="about__item-top">
      <a class="about__item-top-link" target="_blank">
        <img src="@/assets/svg/icon-docs-red.svg" alt=""/>
      </a>
      <ui-input
          :placeholder="$t('document-name')"
          v-model="documentName"
      />
      <ui-select
          :placeholder="$t('document-type')"
          v-model="documentType"
          :options="documentTypes"
      />
      <img
          class="about__item-top-check"
          src="@/assets/svg/icon-check-green.svg"
          alt=""
          @click="addDocument"
          v-if="isEdit && documentName.length && documentFile.length"
      />
      <img
          class="about__item-top-disable"
          src="@/assets/images/check-disable.png"
          alt=""
          v-if="isEdit && (!documentName.length || !documentFile.length)"
      />
      <img
          class="about__item-top-delete"
          src="@/assets/svg/icon-delete-admin.svg"
          alt=""
          @click="visibleAlert=true"
      />
    </div>
    <div class="upload">
      <p class="upload__text">
        {{ $t('document') }}
      </p>
      <div class="upload__wrap">
        <label>
          <input type="file" ref="file" @input="uploadDocument" accept="image/*" >
          <span class="upload__wrap-btn">
            <img src="@/assets/svg/icon-plus-white.svg" alt="" />
          </span>
        </label>
        <a
            v-if="documentFile.length"
            :href="documentSrc.fileSrc"
            target="_blank"
            style="display: flex"
            class="upload__wrap-file"
        >
          <img
              src="@/assets/svg/icon-docs-grey.svg"
              alt=""
          />
          <div class="upload__wrap-file-info">
            <p>{{ documentSrc.name || documentSrc.originalname }}</p>
          </div>
        </a>
      </div>
    </div>
    <ui-alert v-model="visibleAlert">
      <div class="ui-alert__wrap">
        <div class="ui-alert__title">{{ $t('do-you-really-want-to-delete') }}</div>
      </div>
      <div class="ui-alert__action">
        <ui-button @click="deleteDocument" color="error">{{ $t('delete') }}</ui-button>
        <ui-button @click="visibleAlert = false" color="white">{{ $t('cancel-1') }}</ui-button>
      </div>
    </ui-alert>
  </div>
</template>

<script>
import UiInput from "@/components/ui/UiInput.vue";
import {mapActions} from "vuex";
import UiButton from "@/components/ui/UiButton.vue";
import UiAlert from "@/components/ui/UiAlert.vue";
import UiSelect from "@/components/ui/UiSelect.vue";

export default {
  name: "AboutItemNew",
  components: {UiSelect, UiAlert, UiButton, UiInput},

  data () {
    return {
      isEdit: true,
      documentName: '',
      documentFile: '',
      documentSrc: {},
      documentType: {},
      documentTypes: [
        {
          name: this.$t('web-version'),
          type: 'web'
        },
        {
          name: this.$t('mobile-version'),
          type: 'mobile'
        },
        {
          name: this.$t('personal-data'),
          type: 'personal'
        },
        {
          name: this.$t('false-data'),
          type: 'falseData'
        },
      ],
      visibleAlert: false
    }
  },

  methods: {
    ...mapActions(['reqUpload']),
    addDocument() {
      const payload = {
        name: this.documentName,
        file: this.documentFile,
        type: this.documentType.name
      }
      this.$emit('addDocument', payload)
    },

    uploadDocument({ target }) {
      this.reqUpload({
        file: target.files[0],
        type: "document",
      }).then((res) => {
        this.documentFile = res.data.filename
        this.documentSrc = res.data
        this.$refs.file.value = null
      })
    },

    deleteDocument() {
      this.documentName = ''
      this.documentFile = ''
      this.documentSrc = {}
      this.documentType = {}
      this.$emit('closeNewDocument')
    }
  }
}
</script>

<style lang="scss" scoped>
.about__item {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &-top {
    display: flex;
    align-items: center;
    gap: 15px;

    ::v-deep .ui-input {
      max-width: 300px;
    }

    &-link {
      display: flex;
      align-items: center;
      gap: 15px;

      img {
        @media (max-width: 768px){
          display: none;
        }
      }
    }

    &-edit {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }

    &-check {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }

    &-disable {
      width: 17px;
      height: 17px;
      cursor: pointer;
    }

    &-delete {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
}
.upload {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 70px;

  @media(max-width: 768px) {
    margin-left: 20px;
  }

  &__text {
    color: #1B1A1F;
    font-size: 14px;
    font-weight: 400;
  }

  &__wrap {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    overflow-x: auto;
    right: 1rem;
    width: 100%;
    height: 100%;
    padding: 10px 0;

    &-btn {
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #E21F1F;
      color: #fff;
      border-radius: 50%;
      cursor: pointer;
    }

    input {
      display: none;
    }


    &-file {
      position: relative;
      display: flex;
      align-items: center;
      max-width: 200px;
      gap: 10px;

      @media(max-width: 768px) {
        flex-direction: column;
        max-width: 114px;
        min-width: 114px;
      }

      &-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;

        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          color: #1B1A1F;
          word-break: break-all;
        }

        span {
          font-weight: 400;
          font-size: 10px;
          line-height: 112.7%;
          color: #BFBDBD;
          width: 58px;
          text-align: center;
        }
      }
    }
  }
}
</style>
