<template>
  <div class="admin-about about">
    <h1 class="about__title">{{ $t('application-documents') }}</h1>
    <div class="about__content">
      <AboutItem
          v-for="(item, key) in getDocuments"
          :key="key"
          :data="item"
          @deleteDocument="deleteItem"
          @editDocuments="editItem"
          v-show="!visibleLoader"
          show-actions
      />
      <AboutItemNew v-if="isAdd && !visibleLoader" @addDocument="addNewItem" @closeNewDocument="isAdd=false"/>
      <ui-loader v-model="visibleLoader" />
      <p class="about__content-add" @click="isAdd=true">
        <img src="@/assets/svg/icon-plus-red.svg" alt="" />
        {{ $t('new-document') }}
      </p>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AboutItem from "@/components/template/admin/AboutItem.vue";
import AboutItemNew from "@/components/template/admin/AboutItemNew.vue";
import UiLoader from "@/components/ui/UiLoader.vue";

export default {
  name: "index",
  components: {UiLoader, AboutItemNew, AboutItem},

  data () {
    return {
      data: [
        { text: this.$t('application-description'), path: 'https://приложение.экофон.рф/public/upload/raw/p1.html', id: 1, isEdit: false },
        { text: this.$t('instructions-for-use'), path: 'https://приложение.экофон.рф/public/upload/raw/p2.html', id: 2, isEdit: false },
        { text: this.$t('user-agreement'), path: 'https://приложение.экофон.рф/public/upload/raw/p3.html', id: 4, isEdit: false },
        { text: this.$t('methodology-for-calculating-damage'), path: 'https://приложение.экофон.рф/public/upload/raw/p5.html', id: 5, isEdit: false },
      ],
      isEdit: true,
      isAdd: false,
      visibleLoader: true,
      documentName: '',
      documentLink: ''
    }
  },

  computed: {
    ...mapGetters(['getDocuments']),
  },
  methods: {
    ...mapActions(['documents', 'addDocument', 'deleteDocument', 'editDocument']),

    addNewItem (data) {
      this.visibleLoader = true
      this.addDocument(data).then(() => {
        this.documents().then(() => {
          this.visibleLoader = false
          this.isAdd = false
        })
      })
    },

    deleteItem (id) {
      this.visibleLoader = true
      this.deleteDocument(id).then(() => {
        this.documents().then(() => {
          this.visibleLoader = false
        })
      })
    },

    editItem (data) {
      this.visibleLoader = true
      this.editDocument(data).then(() => {
        this.documents().then(() => {
          this.visibleLoader = false
        })
      })
    }
  },
  mounted() {
    this.documents().then(() => {
      this.visibleLoader = false
    })
  }
}
</script>

<style lang="scss" scoped>
.about {
  display: flex;
  justify-content: space-between;
  padding: 150px 163px 56px 50px;
  min-height: 100vh;
  height: 100%;
  background: #F5F5F5;
  gap: 10px;

  @media(max-width: 992px) {
    padding: 150px 50px 56px 50px;
  }

  @media(max-width: 768px) {
    padding: 104px 20px 56px 20px;
    flex-direction: column;
    justify-content: normal;
    gap: 30px;
  }

  &__title {
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    color: #343432;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 42px 40px 46px 40px;
    background: #FFFFFF;
    max-width: 774px;
    width: 100%;
    border-radius: 20px;
    height: max-content;

    @media (max-width: 768px) {
      padding: 30px 12px;
    }

    &-add {
      display: flex;
      align-items: center;
      gap: 10px;
      color: #CE2121;
      margin-top: 50px;
      cursor: pointer;
    }
  }
}
</style>
